import React from "react";
import styled from "styled-components";
import { Section } from "../../components/Core";
import svgHeroShape from "../../assets/image/svg/hero-shape-svg.svg";


const ShapeTopLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;
const Hero = ({ title, children, ...rest }) => {
 // const gContext = useContext(GlobalContext);

  return (
    <>
      <Section style={{"paddingBottom": "0px"}}>
        <ShapeTopLeft>
            <div>
                <img src={svgHeroShape} alt="" className="img-fluid" />
                <p>MEM Safety</p>
            </div>
        </ShapeTopLeft>
      </Section>
    </>
  );
};
export default Hero;
