import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import { Title } from "../../components/Core";
import { device } from "../../utils";
import Img from 'gatsby-image'
import styled from "styled-components";

const HidingCol = styled(Col)`
  display: none;
  @media ${device.lg} {
  display: unset;
  }
`;
const TopLogos = (data) => {
    const images = data.links.industryLinkElements.filter(el => el.featured)

    return (
        <>
                <Container>
                    <Row className={"mt-2 mb-3"}>
                        {images.map((img, i) => (
                            i%2!==0? (
                                    <HidingCol key={i}>
                                        <a
                                            href={`${img.linkSlug}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="text-center">
                                                <Img title={img.shortName} fixed={img.linkLogo.fixed} />
                                            </div>
                                            <div className="text-center">
                                                <strong>{img.shortName}</strong>
                                            </div>
                                        </a>
                                    </HidingCol>
                              ) :
                                    <Col key={i}>
                                        <a
                                            href={`${img.linkSlug}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="text-center">
                                                <Img title={img.shortName} fixed={img.linkLogo.fixed} />
                                            </div>
                                            <div className="text-center">
                                                <strong>{img.shortName}</strong>
                                            </div>
                                        </a>

                                    </Col>
                        ))}
                    </Row>
                </Container>
        </>
    );
};

export default TopLogos;

