import React from "react";
import HomepageBody from "../sections/Homepage/About";
import PageWrapper from "../components/PageWrapper";
import {graphql} from "gatsby";
import Hero from "../sections/Homepage/HeroCircle";
import {Helmet} from "react-helmet";
import CTA from "../sections/Homepage/CTA"
import ImageSlider from "../sections/Homepage/ImageSlider"
import TopLogos from "../sections/Homepage/TopLogos"

const IndexPage = ({ data }) => {
    return (
    <>
        <Helmet>
            <title>{data.contentfulHomePage.pageTitle}</title>
        </Helmet>
        <PageWrapper footerDark>
            <Hero title={data.contentfulHomePage.pageTitle}> </Hero>
            <TopLogos links={data.contentfulIndustryLinks} />
            <HomepageBody homePage={data.contentfulHomePage} />
            <ImageSlider images={data.contentfulGalleryImages} />
            <CTA/>
      </PageWrapper>
    </>
  );
};
export const query = graphql`
    {
        contentfulHomePage(pageTitle: {eq: "MEM Safety Training"}) {
            mainTitle
            mainSubtitle
            pageTitle
            bodyText { json }
            lowerBodyText { lowerBodyText }
            mainImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
        contentfulGalleryImages {
            name
            galleryImages{
                description
                fluid(maxWidth: 760) {
                    ...GatsbyContentfulFluid
                }
            }
        }
        contentfulIndustryLinks {
            industryLinkElements {
                linkSlug
                shortName
                longName
                featured
                linkLogo {
                    fixed(height: 75) {
                        ...GatsbyContentfulFixed
                    }
                    fluid(maxHeight: 125) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }

    }`
export default IndexPage;
