import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import Img from "gatsby-image";

const ImgContainer = styled(Box)`
  /* border-radius: 10px; */
  overflow: hidden;
`;

const About = (props) => {
        return (
  <>
    <Section style={{"paddingTop": "30px"}}>
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title>{ props.homePage.mainTitle }</Title>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <Box>
                <Text mb={4}>
                  { props.homePage.bodyText.json.content[0].content[0].value + " " +  props.homePage.bodyText.json.content[0].content[1].value}
                </Text>
                <Text mb={4}>
                  { props.homePage.lowerBodyText.lowerBodyText}
                </Text>
              </Box>
            </div>
          </Col>
          <Col lg="6">
            <ImgContainer className="text-center">
              <Img alt="" className="img-fluid" fluid={props.homePage.mainImage.fluid} style={{paddingBottom: "20%"}} />
            </ImgContainer>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)};

export default About;
