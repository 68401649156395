import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { rgba } from "polished";
import { Title, Section, Box } from "../../components/Core";
import { device, breakpoints } from "../../utils";

const SliderStyled = styled(Slider)`
  position: relative;
  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 65px;
    display: flex;
    width: 65px;
    background: ${({ theme }) => theme.colors.primary};
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      transition: 0.4s;
    }
  }
    @media ${device.md} {
      margin-right: 60px;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
  padding: 0 20px;
`;

const SliderCard = styled(Box)`
  background: #fff;
  overflow: hidden;
  box-shadow: ${({ theme }) =>
    `0 52px 54px ${rgba(theme.colors.shadow, 0.125)}`};
  display: flex;
  flex-direction: row;
  padding: 20px;
   @media ${device.md} {
    padding: 10px;
  }
`;

const SliderImgContainer = styled(Box)`
  overflow: hidden;

  @media ${device.sm} {
    padding: 0;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`;

const ImageSlider = (data) => {
  const images = data.images.galleryImages.filter((image) => !image.description.includes('irregular'))
  const slickSettings = {
    dots: false,
    infinite: true,
    pauseOnHover: true,
    speed: 2000,
    autoplay: true,
    autoPlaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Section bg="#F7F7FB">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col xl="9" lg="12">
              <div className="text-center">
                <Title>Featured Photos</Title>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" xl="12">
              <SliderStyled {...slickSettings} className="mr-0">
                {images.map((img, i) => (
                    <SliderItem key={i}>
                      <SliderCard>
                        <SliderImgContainer>
                          <img alt="" src={img.fluid.src} />
                        </SliderImgContainer>
                      </SliderCard>
                    </SliderItem>
                ))}
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default ImageSlider;
